import copy from "copy-to-clipboard";
import React, { Component } from "react";
import styled from "styled-components";
import { Button, H2, H4, HR, P } from "@upsolve/ui";
import { EVENTS } from "@upsolve/shared";
import { track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import Page from "../components/Layout/Page.div";
import MainFooter from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import { CTATarget } from "../components/CTA/getCTAData";

const contentGroup = CONTENT_GROUPS.LANDING_PAGE;
const templateProps = {
  pageComponentName: "ToolsPage",
  pageComponentVersion: 0,
};
const trackingPageContext = { contentGroup, ...templateProps };

export default class ToolsPage extends Component {

  componentDidMount() {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }

  render() {
    return (
      <TopLevelLayout>
        <SEO
          title="Bankruptcy and Financial Tools | Upsolve"
          description="Shareable tools for your website to help visitors navigate debt and finances."
          image="https://upsolve.org/images/meta-legalaid.png"
          url="https://upsolve.org/tools/"
        />
        <Header trackingPageContext={trackingPageContext} ctaTarget={CTATarget.DEBT_TRIAGE}/>
        <main>
          <ToolListingPage>
            <div className="tools__header">
              <H2 as="h1">Bankruptcy and Financial Tools</H2>
              <P>
                Below are tools and calculators we've made that anyone can embed in their website to give visitors
                assistance and knowledge about their financial future.
              </P>
            </div>
            <HR />
            <div className="tools__list">
              <div className="tool">
                <div className="tool__header">
                  <H4 as="h2">Debt Repayment Calculator</H4>
                </div>
                <iframe width="100%" height="640px" src="/tools/debt-repayment-calculator" />
                <div className="tool__code">
                  <div className="code-block">
                    <pre>
                      {`<iframe width="100%" height="640px" src="https://upsolve.org/tools/debt-repayment-calculator" />`}
                    </pre>
                  </div>
                  <Button
                    size="sm"
                    inverted
                    onClick={() =>
                      copy(
                        `<iframe width="100%" height="640px" src="https://upsolve.org/tools/debt-repayment-calculator" />`
                      )
                    }
                  >
                    Copy Tool Code
                  </Button>
                </div>
              </div>
            </div>
          </ToolListingPage>
        </main>
        <MainFooter />
      </TopLevelLayout>
    );
  }
}

const ToolListingPage = styled(Page)`
  margin: 3em auto 6em;
  .tools__list {
    .tool {
      margin: 2.5em 0;
      .tool__header {
        display: flex;
        justify-content: center;
        margin: 1em 0;
        h2 {
          color: ${(props) => props.theme.colors.brand[500]};
        }
      }
      iframe {
        outline: none;
        border-radius: 12px;
        overflow: hidden;
        border: 4px solid ${(props) => props.theme.colors.brand[500]};
      }
      .tool__code {
        .code-block {
          margin: 0.5em 1em;
          pre {
            width: 100%;
            background: ${(props) => props.theme.colors.white[800]};
            border: 1px solid ${(props) => props.theme.colors.white[300]};
            border-radius: 4px;
            padding: 1em;
            overflow-x: hidden;
          }
        }
        & > div,
        & > button {
          margin: 0 auto;
        }
      }
    }
  }
`;
